import { useState, useEffect } from "react"
import RSCard from "rsui/RSCard"
import TagList from "./component/TagList"
import RSSelector from "rsui/RSSelector"
import { getTags, updateTag } from "api/tag"
import { Chip, Stack } from "@mui/material"

export default function ProductTags() {

  const [type, setType] = useState('3')
  const [tags, setTags] = useState([])

  useEffect(() => {
    fetchData()
  }, [type])

  const fetchData = async () => {
    const data = await getTags(type)
    if(data) setTags(data)
  }

  const changeType = (option) => {
    setType(option.key)
  }

  const options = [
    {key: '3', value: '分类'},
    {key: '0', value: '品牌'},
    {key: '1', value: '系列'},
    {key: '9', value: '产地'}
  ]

  const addNew = async () => {
    await updateTag({type: type, name_cn: '-'})
    fetchData()
  }


  const Actions = () => 
  <Stack direction="row">
    <Chip label="新建" onClick={addNew} />
    <RSSelector name="标签类" options={options} onSelect={changeType} selectedKey={type} />
  </Stack>

  return (
    <RSCard title="产品标签" action={<Actions />}>
      <TagList tags={tags} callback={fetchData} />
    </RSCard>
  )
}