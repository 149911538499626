import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import MenuItems from "./MenuItems";

const menu = {
  "text": "系统设置",
  "icon": SettingsIcon,
  "permission": "admin",
  "sub": [
    {
      "text": "邮件模板",
      "path": "/setting/email-template"
    },  
    {
      "text": "版本设置",
      "path": "/setting/versions"
    },  
    {
      "text": "客户搜索",
      "path": "/customer/search"
    }
  ]
}

export default function MenuItemSystem() {

  return <MenuItems menu={menu} />
}