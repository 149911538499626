
import { Grid, Stack, Typography } from '@mui/material'
import SearchInput from 'components/common/SearchInput';
import { useState } from 'react';
import call from 'utils/call';

export default function CustomerSearch() {

  const [customers, setCustomers] = useState([])

  const search = async (keywords) => {
    const data = await call('customer/search', {keywords: keywords})
    if(data) {
      setCustomers(data)
    } else {
      setCustomers([])
    }
  }

  return (
    <Grid 
      container
      justifyContent="center"
    >
      <Grid item xs={4}>
        <Stack>
          <SearchInput search={search} placeholder="email" />
          {customers && customers.map(
            customer => <Typography key={customer.id}>{customer.id} {customer.email}</Typography>
          )}
        </Stack>
      </Grid>
    </Grid>
  )
}