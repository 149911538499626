/* 标签 接口 */

import call from 'utils/call' 

/***************************************************************/
export async function getTags(type) {
  console.log('标签列表')
  return await call('tag/tags/'+type)
}

/***************************************************************/
export async function updateTag(data) {
  console.log('更新标签')
  await call('tag/update', data)
}

/***************************************************************/
export async function getProductNum(tagID) {
  console.log('关联产品数字')
  return await call('tag/productNumber/'+tagID)
}