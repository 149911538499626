import { useState } from 'react'
import { Chip, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useEffect } from 'react';

/**
 * Selector 选择器
 * @param { string } name 标题
 * @param { int } selectedKey 选中的 Key 值, 只在初始化的时候影响
 * @param { string } selectedValue 选中的值, 只在初始化的时候影响
 * @param { array } options 选项数组 [{key, vaule},...] key any string or int 不要用0
 * @param { function } onSelect 选中时候的回调函数
 * @returns 
 */
export default function RSSelector({ name, selectedKey, selectedValue, options, onSelect, color = 'default'}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedOption, setSelectedOption] = useState()

  const openMenu = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const select = (event, item) => {
    event.stopPropagation()
    setSelectedOption(item)
    if(onSelect) onSelect(item)
    closeMenu()
  }

  useEffect(() => {
    if(selectedKey) {
      const optionByKey = options.find(option => option.key === selectedKey);
      setSelectedOption(optionByKey)
    }else if(selectedValue) {
      const optionByValue = options.find(option => option.value === selectedValue);
      setSelectedOption(optionByValue)
    }else {
      setSelectedOption(options[0])
    }
    
  }, [selectedKey, selectedValue])

  return (
    <>

    <Chip label={selectedOption?selectedOption.value:''} onClick={openMenu} color={color} />

    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenu}
    >
      {name && 
      <Stack alignItems="center" sx={{borderBottom: '1px solid #ccc', paddingBottom: '0.5rem', marginBottom: '0.5rem'}}>
        <Typography><b>{name}</b></Typography>
      </Stack>
      }
      {options.map((item, index) => <MenuItem key={index} onClick={(e)=>select(e, item)} dense>{item.value}</MenuItem>)}
    </Menu>
    
    </>
  )
}