/* 搜索栏 */
import { useState } from 'react'
import { Search, Close } from '@mui/icons-material'
import { Paper, InputBase, IconButton, Stack } from '@mui/material'

export default function SearchInput(props) {
  const { placeholder, search } = props
  const [keywords, setKeywords] = useState('')

  const changeHandle = (e) => {
    setKeywords(e.target.value)
  }

  const clearHandle = () => {
    setKeywords('')
  }

  const searchHandle = () => {
    if(!keywords || keywords.trim() === '') return
    search(keywords)
  } 

  return (
  <Paper sx={{padding: 1}}>
    <Stack direction="row" spacing={1}>
      <InputBase
        sx={{flex: 1}}
        ml={1}
        placeholder={placeholder}
        onChange={changeHandle}
        value={keywords}
        onKeyUp={(e)=>{
          if(e.key==="Enter") {
            searchHandle()
          }
        }}
      />

      { keywords !== "" && 
        <IconButton p={10} onClick={clearHandle} >
          <Close fontSize="small" color="primary" />
        </IconButton>
      }
      <IconButton p={10} onClick={searchHandle}>
        <Search />
      </IconButton>
    </Stack>
    
    
  </Paper>
  )
}