import { Box, Chip, Stack, Typography } from "@mui/material";
import { getProductNum, updateTag } from "api/tag";
import { useState } from "react";
import RSDialog from "rsui/RSDialog";
import RSForm from "rsui/RSForm";


export default function TagList({ tags, callback }) {

  const [open, setOpen] = useState(false)
  const [tag, setTag] = useState(null)
  const [linked, setLinked] = useState(0)

  const openDialog = async (t) => {
    setTag(t)
    setOpen(true)
    const data = await getProductNum(t.id)
    if(data) setLinked(data)
  }

  const closeDialog = () => {
    setOpen(false)
    setLinked(0)
  }

  const form = [{
    contents: [
      {
        type: 'input',
        name: 'name_cn',
        label: '标签-中文'
      },
      {
        type: 'input',
        name: 'name_en',
        label: 'Tag-English'
      }
    ]
  }]

  const saveTag = async (data) => {
    await updateTag(data)
    callback()
    closeDialog()
  }

  return (
    <Box>

      {
        tags.map(t => <Chip key={t.id} label={t.name_cn} style={{marginRight:5, marginBottom: 5}} onClick={()=>openDialog(t)} />)
      }

      <RSDialog 
        title="标签设置"
        open={open}
        onClose={closeDialog}
      >
        <Stack spacing={2}>
          <Typography>关联产品 {parseInt(linked)?linked:''}</Typography>
          {tag && <RSForm data={tag} fields={form} onSave={saveTag}  />}
        </Stack>
      </RSDialog>
    </Box>
  )
}