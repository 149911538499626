import { WebOutlined } from '@mui/icons-material';
import MenuItems from "./MenuItems";


const menu = {
  "text": "网站管理",
  "icon": WebOutlined,
  "sub": [
    {
      "text": "Banner",
      "path": "/website/banner"
    },
    {
      "text": "通知",
      "path": "/website/notice"
    }
  ]
}

export default function MenuItemWeb() {

  return <MenuItems menu={menu} />
}