import { Typography, Stack, TextField } from '@mui/material'
import RSCard from 'rsui/RSCard'
import { couponAnalytics } from '../api'
import { useState } from 'react'
import OrderListItem from 'pages/new-orders/components/OrderListItem'

// 优惠码 分析
export default function CouponAnalytics() {
  
  const [analytics, setAnalytics] = useState()
  const [code, setCode] = useState('')
  const [orders, setOrders] = useState([])

  const changeCode = (e) => {
    setCode(e.target.value)
  }

  const pressEnter = (event) => {
    if(event.key === 'Enter'){
      if(code && code.length >= 1) {
        analyze()
      }
    }
  }

  const analyze = async () => {
    setOrders([])
    const data = await couponAnalytics(code)
    if(data) {
      setAnalytics(data.data)
      setOrders(data.orders)
    }
  }

  return (
  <RSCard title="优惠码统计" action={<TextField label="优惠码" size='small' value={code} onChange={changeCode} onKeyDown={pressEnter} />}>
    {analytics && 
    <Stack>
      <Typography>订单数：{analytics.count}</Typography>
      <Typography>销售金额：£{analytics.amount}</Typography>
      <Stack>
        {orders && orders.map(order=> <OrderListItem order={order} key={order.id} />)} 
      </Stack>
    </Stack>
    }
  </RSCard>
  )
}