import { useState, useEffect } from 'react';
import { Grid, Stack, TextField } from '@mui/material'
import { Button } from '@mui/material'
import { getNotice, saveNotice } from './api';
import { showSuccess } from 'utils/layout';
import RSCard from 'rsui/RSCard';

export default function CheckoutNotice() {
  const [notice, setNotice] = useState('')

  const loadData = async () => {
    const data = await getNotice()
    if(data) setNotice(data)
  }

  useEffect(()=>{
    loadData()
  }, [])

  const editEN = e => {
    setNotice({
      ...notice,
      en: e.target.value
    })
  }

  const editCN = e => {
    setNotice({
      ...notice,
      cn: e.target.value
    })
  }

  const handleSave = async () => {
    await saveNotice(notice)
    showSuccess('已保存')
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} >
        <RSCard title="下单提醒 - Web,APP下单的时候显示给客户">
          <Stack>
            <TextField multiline fullWidth value={notice.cn} onChange={editCN} />
            <TextField multiline fullWidth value={notice.en} onChange={editEN} />
            <Button variant='contained' onClick={handleSave}>保存</Button>
          </Stack>
        </RSCard>
       
      </Grid>
    </Grid>
)
}